import React, { useEffect, useRef } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Banner from "../../components/banner"

const Contenido = (props) => {
  const isMountedComponent = useRef(true)
  useEffect(() => {
    if (isMountedComponent.current) {
      window.jquery_scripts()
      var _wsc = document.createElement("script")
      _wsc.src = "//tools.prnewswire.com/es/live/13804/landing.js"
      document.getElementsByTagName("Head")[0].appendChild(_wsc)
    }
    return () => {
      isMountedComponent.current = false
    }
  })
  return (
    <Layout location={props.location}>
      <SEO title="News"/>
      <Banner title="News"/>
      <section className="executive_area equipo">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div id="w13804_landing"></div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Contenido
